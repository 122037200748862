module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZeroBounce","short_name":"ZB","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","cache_busting_mode":"none","icons":[{"src":"/favicon.ico","sizes":"any","type":"image/x-icon","purpose":"any maskable"},{"src":"/favicon.png","sizes":"32x32","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
