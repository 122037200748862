import React, { createContext, useState, useEffect } from 'react';
import { APIBaseUrl } from '../i18n/environments';

export const PricingContext = createContext<any>({
  customPackages: [],
  paygValues: [],
  monthlyPrice: [],
  loading: false,
  error: '',
  setShouldFetch: () => {},
});

const pricingUrl = `${APIBaseUrl}/payments/pricing/`;

const PricingContextProvider = ({ children }) => {
  //controls where this should fetch the pricing data so we don't fetch on every page
  const [shouldFetch, setShouldFetch] = useState(false);
  const [customPackages, setCustomPackages] = useState([]);
  const [paygValues, setPaygValues] = useState([]);
  const [monthlyPrice, setMonthlyPrice] = useState([]);
  const [annualPrice, setAnnualPrice] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (shouldFetch) {
      const getPricingInfo = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(pricingUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          if (data) {
            formatPricingPackages(data);
            formatPaygValues(data);
            setMonthlyPrice(data.email_subscriptions);
            setIsLoading(false);
            setError('');
          }
        } catch (error) {
          setIsLoading(false);
          setError('An error occurred while retrieving pricing data. Please try again later.');
        }
      };
      getPricingInfo();
    }
  }, [shouldFetch]);

  const formatPricingPackages = (data) => {
    const freemiumPackage = {
      amount_display: 0,
      blacklist_monitors: 1,
      blm_scan_frequency: 24,
      dmarc_domains: 0,
      email_finder_searches: 10,
      interval: 'month',
      max_email_auth_tests: 1,
      max_inbox_tests: 1,
      nick_name: 'Freemium',
      warmup_accounts: 0,
      warmup_actions: 0,
      validation_credits: 100,
    };
    const sortedPackages = [...data.tools_subscriptions].sort((a, b) => {
      const amountA = parseFloat(a.amount_display.toString().replace(/[^\d.]/g, ''));
      const amountB = parseFloat(b.amount_display.toString().replace(/[^\d.]/g, ''));

      return amountA - amountB;
    });

    sortedPackages[0] = freemiumPackage;
    const transformedPackages = sortedPackages.map((item) => {
      return {
        ...item,
        amount_display: parseInt(item.amount_display.toString().replace(/[^\d.]/g, '')),
      };
    });

    transformedPackages.pop();
    setCustomPackages(transformedPackages);

    const sortedAnnualPackages = [...data.tools_annually_plans].sort((a, b) => {
      const amountA = parseFloat(a.amount_display.toString().replace(/[^\d.]/g, ''));
      const amountB = parseFloat(b.amount_display.toString().replace(/[^\d.]/g, ''));

      return amountA - amountB;
    });

    const transformedAnnualPackages = sortedAnnualPackages.map((item) => {
      return {
        ...item,
        amount_display: parseInt(item.amount_display.toString().replace(/[^\d.]/g, '')),
      };
    });
    transformedAnnualPackages.unshift(freemiumPackage);

    const updatedAnnualPrice = transformedAnnualPackages.map((annual, idx) => {
      const annualAmount = parseFloat(annual.amount_display.toString().replace(/[^\d.]/g, ''));
      const monthlyAmount = parseFloat(transformedPackages[idx].amount_display.toString().replace(/[^\d.]/g, ''));

      return {
        ...annual,
        difference: monthlyAmount * 12 - annualAmount,
      };
    });
    setAnnualPrice(updatedAnnualPrice);
  };

  const formatPaygValues = (data) => {
    const transformedPrice = data.pricing_chart.map((item) => ({
      price: item.priceperemail,
      startValue: item.minemail,
      id: item.package_cost,
    }));
    transformedPrice.pop();
    transformedPrice.pop();
    transformedPrice.unshift({
      price: 0,
      startValue: 100,
      id: 0,
    });
    setPaygValues(transformedPrice);
  };

  const sharedValues = {
    customPackages,
    paygValues,
    monthlyPrice,
    annualPrice,
    loading,
    error,
    setShouldFetch,
  };

  return <PricingContext.Provider value={sharedValues}>{children}</PricingContext.Provider>;
};

export default PricingContextProvider;
