import React from 'react';
import PricingContextProvider from './src/contexts/pricingContext';
import ReactDOM from 'react-dom/client';
import Cookie from 'js-cookie';

const COOKIE_NAME = 'im_ref';
const COOKIE_EXPIRATION_DATE = 30;

export const wrapRootElement = ({element}) => <PricingContextProvider>{element}</PricingContextProvider>;

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get(COOKIE_NAME);

    if (paramValue) {
      const existingCookie = Cookie.get(COOKIE_NAME);
      if (!existingCookie) {
        Cookie.set(COOKIE_NAME, paramValue, {expires: COOKIE_EXPIRATION_DATE});
      } else if (existingCookie && existingCookie === paramValue) {
        Cookie.set(COOKIE_NAME, existingCookie, {expires: COOKIE_EXPIRATION_DATE});
      }
    }
  }
}
