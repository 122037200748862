// this configuration allows translations to be deployed together on the same domain or on different domains
export const environments = {
  dev: {
    alternates: [
      {
        lang: 'en',
        path: 'http://localhost:8000/',
      },
      {
        lang: 'es',
        path: 'http://localhost:8000/es/',
      },
    ],
  },
  test: {
    alternates: [
      {
        lang: 'en',
        path: 'https://test-web.zerobounce.net/',
      },
      {
        lang: 'es',
        path: 'https://test-web.zerobounce.net/es/',
      },
    ],
  },
  staging: {
    alternates: [
      {
        lang: 'en',
        path: '.https://staging.zerobounce.net/',
      },
      {
        lang: 'es',
        path: 'https://staging.zerobounce.net/es/',
      },
    ],
  },
  xyz: {
    alternates: [
      {
        lang: 'en',
        path: 'https://www.zerobounce.xyz/',
      },
      {
        lang: 'es',
        path: 'https://www.zerobounce.xyz/es/',
      },
    ],
  },
  prod: {
    alternates: [
      {
        lang: 'en',
        path: 'https://www.zerobounce.net/',
      },
      {
        lang: 'es',
        path: 'https://www.zerobounce.net/es/',
      },
    ],
  },
};

// these are the names to be displayed for language+locale
export const languageNames = {
  en: 'English',
  es: 'Español',
};

// this is used to differentiate en / en-in, use useCurrentLanguage to differentiate en / es / en-in
// const isEnBuild = process.env.REACT_APP_CURRENT_BUILD === 'en';
export const isEnInBuild = process.env.REACT_APP_CURRENT_BUILD === 'en-in';

// used when needed to render something based on which environment you are on
export const isProdBuild = process.env.REACT_APP_ENV === 'prod';
export const isStagingBuild = process.env.REACT_APP_ENV === 'xyz';

export const membersRelativeBaseUrl = !isProdBuild
  ? isStagingBuild
    ? 'https://www.zerobounce.xyz/members'
    : 'https://test-members.zerobounce.net'
  : isEnInBuild
  ? 'https://www.zerobounce.in/members'
  : 'https://www.zerobounce.net/members';

export const relativeBaseUrl = !isProdBuild
  ? isEnInBuild
    ? 'https://test-web.zerobounce.in'
    : 'https://test-web.zerobounce.net'
  : isEnInBuild
  ? 'https://www.zerobounce.in'
  : 'https://www.zerobounce.net';

export const loginUrl = !isProdBuild
  ? isStagingBuild
    ? 'https://www.zerobounce.xyz/members/signin'
    : 'https://test-members.zerobounce.net/signin'
  : isEnInBuild
  ? 'https://www.zerobounce.in/members/signin'
  : 'https://www.zerobounce.net/members/signin';

export const oktaIssuerUrl = !isProdBuild
  ? 'https://zerobounce.oktapreview.com/oauth2/default'
  : isEnInBuild
  ? 'https://okta.zerobounce.in/oauth2/default'
  : 'https://okta.zerobounce.net/oauth2/aus59q4ffwAfaJK5Q4x7';

function getRegisterUrl(isProdBuild, isStagingBuild, isEnInBuild) {
  const url = !isProdBuild
    ? isStagingBuild
      ? 'https://www.zerobounce.xyz/members/signin/register'
      : 'https://test-members.zerobounce.net/signin/register'
    : isEnInBuild
    ? 'https://www.zerobounce.in/members/signin/register'
    : 'https://www.zerobounce.net/members/signin/register';

  if (typeof window !== 'undefined') {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'click_signup',
      });
    }
  }

  return url;
}
export const registerUrl = getRegisterUrl(isProdBuild, isStagingBuild, isEnInBuild);

export const warmupBrandedRegisterUrl = registerUrl.replace('register', 'register-email-warmup');
export const dmarcBrandedRegisterUrl = registerUrl.replace('register', 'register-dmarc');
export const emailFinderBrandedRegisterUrl = registerUrl.replace('register', 'register-email-finder');

export const oktaClientId = !isProdBuild ? '0oa4qm0r6noj3ulFH1d7' : isEnInBuild ? '0oa2mssgi6TmVaZil697' : '0oa6a2e1vwzhRgeio4x7';

export const relativeSupportEmail = isEnInBuild ? 'support@zerobounce.in' : 'support@zerobounce.net';

// Currently we have only 2 build types: en and en-in. Spanish is not a build type.
// Furthermore, sometimes is required to only have either .in or .net
export const buildUrlEnding = isStagingBuild ? '.xyz' : isEnInBuild ? '.in' : '.net';

export const ABTestingEncrypt = '61PjnRll5XvebyLQFgYdxZ0zPAl0pYyEEqde6guMS8U=';

export const abTestingAPI = isProdBuild
  ? 'https://activity-flow.zerobounce.net/add-click'
  : 'https://test-tracking-web.zerobounce.net/add-click';

// Members BE test url for test environment. !!See ZW-302 comments!! Currently there is no india test api url and so we should use the production one for now
export const apiBaseUrl = !isProdBuild
  ? isStagingBuild
    ? 'https://members-api.zerobounce.xyz/api'
    : 'https://test-members-api.zerobounce.net/api'
  : 'https://extension-api.zerobounce.net/api';

// For now we dont special endpoints for staging as they are not finished by the BE and members team but we will at some point and then this should be rewritten

export let APIBaseUrl;
export let recaptcha_site_key;
switch (process.env.REACT_APP_ENV) {
  case 'xyz': {
    APIBaseUrl = 'https://members-api.zerobounce.xyz/api';
    recaptcha_site_key = '6Lf3_AUhAAAAACCaq6ZQ7YgUcF1aLr-q6uc-rno4';
    break;
  }
  case 'prod': {
    APIBaseUrl = 'https://members-api.zerobounce.net/api';
    recaptcha_site_key = '6LctfgYhAAAAAF0R7cNWaJIovInqEctXxwO92EN9';
    break;
  }
  default: {
    APIBaseUrl = isEnInBuild ? 'https://members-api.zerobounce.in/api' : 'https://test-members-api.zerobounce.net/api';
    recaptcha_site_key = '6Lf3_AUhAAAAACCaq6ZQ7YgUcF1aLr-q6uc-rno4';
    break;
  }
}
